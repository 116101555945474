// import useSWR from 'swr';

// import { useEffect } from 'react';

import { createContext, useEffect, useReducer } from 'react';

import PropTypes from 'prop-types';
// routes
// import { PATH_AUTH } from '../routes/paths';
//
// import { AUTH0_API } from '../config';

// ----------------------------------------------------------------------
// import useSWR from 'swr';
import axios from '../utils/axios';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'laravel',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await getUser();
        if (user) {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        console.error(err);
      }
    };

    initialize();
  }, []);

  const getUser = () => axios.get('/api/me').then((res) => res.data);

  const csrf = () => axios.get('/sanctum/csrf-cookie');

  const login = async (props) => {
    await csrf();

    await axios.post('/login', props);

    dispatch({ type: 'LOGIN', payload: { user: await getUser() } });
  };

  const logout = () => {
    axios.post('/logout').then(() => dispatch({ type: 'LOGOUT' }));
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'laravel',
        user: {
          id: state?.user?.id,
          photoURL: state?.user?.picture,
          email: state?.user?.email,
          phone: state?.user?.phone,
          displayName: state?.user?.name,
          branch: state?.user?.branch,
          branchId: state?.user?.branchId,
          role: (state?.user?.role && state?.user?.role[0]) || 'anonymous',
        },
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
