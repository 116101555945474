import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { fNumber } from '../../utils/formatNumber';
import { saleTypes } from '../../constants';

// ----------------------------------------------------------------------

const initialState = {
  filteredProducts: [],
  selectedCartItem: null,
  checkout: {
    customer: null,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    deliveryCost: null,
    paymentType: null,
    abaTransactionId: null,
    location: null,
  },
  saleType: null,
  delivery: {
    driver: null,
    cost: null,
    expectedDeliveryDateTime: null,
  },
  isSaleSuccessful: false,
};

const slice = createSlice({
  name: 'pos',
  initialState,
  reducers: {
    setSelectedCartItem(state, action) {
      state.selectedCartItem = action.payload;
    },

    setCustomerInformation(state, action) {
      state.checkout.customer = action.payload;
    },

    setDeliveryInformation(state, action) {
      state.delivery = action.payload;
    },

    setSaleType(state, action) {
      state.saleType = action.payload;
    },

    setFilteredProducts(state, action) {
      state.filteredProducts = action.payload;
    },

    setTotal(state, action) {
      state.total = action.payload;
    },

    setLocation(state, action) {
      state.checkout.location = action.payload;
    },

    // CHECKOUT
    // getCart(state, action) {
    //   const cart = action.payload;

    //   const subtotal = sum(
    //     cart.map((cartItem) => (cartItem.ppu - cartItem.ppu * (cartItem.discount / 100)) * cartItem.quantity)
    //   );

    //   state.checkout.cart = cart;
    //   state.checkout.subtotal = Number(subtotal.toFixed(2));
    //   const discountAmount = state.checkout.subtotal * (state.checkout.discount / 100);
    //   state.checkout.total = Number((state.checkout.subtotal - discountAmount).toFixed(2));
    // },
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((cartItem) => (cartItem.ppu - cartItem.ppu * (cartItem.discount / 100)) * cartItem.quantity)
      );

      state.checkout.cart = cart;
      state.checkout.subtotal = Number(subtotal.toFixed(2));
      const discountAmount = state.checkout.subtotal * (state.checkout.discount / 100);
      state.checkout.total = Number((state.checkout.subtotal - discountAmount).toFixed(2));
    },

    addCart(state, action) {
      let product = action.payload;
      product = { ...product, quantity: 1, discount: 0, index: state.checkout.cart.length };
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) throw new Error(`"${product.name}" already in cart`);
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      let updateCart = state.checkout.cart.filter((item) => item.id !== action.payload.id);

      updateCart = updateCart.map((item) => {
        if (item.index > action.payload.index) {
          return {
            ...item,
            index: item.index - 1,
          };
        }
        return item;
      });

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.cart.splice(0, state.checkout.cart.length);
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.customer = null;
      state.checkout.abaTransactionId = null;
      state.checkout.paymentType = null;
      state.selectedCartItem = null;
      state.saleType = null;
      state.delivery.cost = null;
      state.delivery.driver = null;
      state.delivery.expectedDeliveryDateTime = null;
      state.checkout.location = null;
      state.isSaleSuccessful = false;
    },

    resetDelivery(state) {
      state.delivery.driver = null;
      state.delivery.cost = null;
      state.delivery.expectedDeliveryDateTime = null;
    },

    resetSaleType(state) {
      state.saleType = null;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId && product.stockAmount > product.quantity) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    setQuantity(state, action) {
      const { productId, quantity } = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId && product.quantity > 1) {
          return {
            ...product,
            quantity: product.quantity - 1.0,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    increaseDiscount(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId && product.quantity < 100) {
          return {
            ...product,
            discount: product.discount + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseDiscount(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId && product.discount > 0) {
          return {
            ...product,
            discount: product.discount - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    setDiscount(state, action) {
      const { productId, discount } = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            discount,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    setPaymentType(state, action) {
      state.checkout.paymentType = action.payload;
    },

    // applyDiscount(state, action) {
    //   const discount = action.payload;
    //   state.checkout.discount = discount;
    //   state.checkout.total = state.checkout.subtotal * (1 - discount / 100);
    // },
    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      const discountAmount = state.checkout.subtotal * (discount / 100);
      state.checkout.total = Number((state.checkout.subtotal - discountAmount).toFixed(2));
    },

    saleSuccessful(state) {
      state.isSaleSuccessful = true;
    },

    saleUnSuccessful(state) {
      state.isSaleSuccessful = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCustomerInformation,
  setDeliveryInformation,
  setFilteredProducts,
  setSelectedCartItem,
  setSaleType,
  setLocation,
  getCart,
  addCart,
  resetCart,
  resetDelivery,
  resetSaleType,
  deleteCart,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  setQuantity,
  increaseDiscount,
  decreaseDiscount,
  setPaymentType,
  setDiscount,
  saleSuccessful,
  saleUnSuccessful,
} = slice.actions;
