import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';

import { HOST_API } from '../../config';

export const deliveryApi = createApi({
  reducerPath: 'deliveryApi',
  baseQuery: axiosBaseQuery({
    baseUrl: HOST_API,
  }),
  tagTypes: ['Delivery', 'MyAccount'],
  endpoints(builder) {
    return {
      getDeliveriesForDriver: builder.query({
        query: (id) => ({
          url: `/api/drivers/${id}/deliveries`,
          method: 'get',
        }),
        providesTags: [{ type: 'Delivery', id: 'LIST' }],
      }),
      getDriverAccountInformation: builder.query({
        query: (id) => ({
          url: `/api/drivers/${id}/my-account`,
          method: 'get',
        }),
        providesTags: [{ type: 'MyAccount' }],
      }),
    };
  },
});

export const { useGetDeliveriesForDriverQuery, useGetDriverAccountInformationQuery } = deliveryApi;
