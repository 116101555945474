const CONST = {
  ROLES: ['cashier', 'driver'],
  saleTypes: ['In-store', 'Online'],
  ORDER_STATUS: ['Ordered', 'Transit', 'Completed'],
  PAYMENTS: ['CASH', 'QR'],
  statusMapper: {
    ORDERED: 'Transit',
    TRANSIT: 'Completed',
    COMPLETED: '',
  },
  colorStatusMapper: {
    ORDERED: 'warning',
    TRANSIT: 'primary',
    COMPLETED: 'secondary',
  },
};

export const { ROLES, saleTypes, ORDER_STATUS, statusMapper, colorStatusMapper, PAYMENTS } = CONST;
