import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
// import productReducer from './slices/product';
import posReducer from './slices/pos';
// api/s
import {
  userApi,
  branchApi,
  productApi,
  exchangeRateApi,
  inventoryApi,
  posApi,
  orderApi,
  dashboardApi,
  deliveryApi,
} from './api';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: chatReducer,
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
  // product: persistReducer(productPersistConfig, productReducer),
  pos: posReducer,
  [userApi.reducerPath]: userApi.reducer,
  [deliveryApi.reducerPath]: deliveryApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [branchApi.reducerPath]: branchApi.reducer,
  [exchangeRateApi.reducerPath]: exchangeRateApi.reducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [posApi.reducerPath]: posApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
});

export { rootPersistConfig, rootReducer };
